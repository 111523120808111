import {
  AppModule,
  AppState,
  ConsumingApp,
  ExtendedCustomModule,
  initAppModule,
} from '@caresend/ui-components';

import type { CustomActionContext, RootState } from '@/store/model';
import { ExtendedVariablesActions } from '@/store/modules/variables';

type S = AppState;

type ExtraAppActionContext = CustomActionContext<'app', S>;

export type ExtraAppActions = {
  'app/resetStore': (
    context: ExtraAppActionContext,
  ) => void;
};

const extraAppActions: ExtraAppActions = {
  'app/resetStore': ({ commit }) => {
    commit('bookings/RESET_BOOKINGS');
    commit('users/RESET_STAFF_USERS');
    commit('procedures/RESET_PROCEDURES');
  },
};

const appModuleExtension = {
  actions: extraAppActions,
};

export const appModule: ExtendedCustomModule<
AppModule<RootState, ExtendedVariablesActions>,
typeof appModuleExtension
> = initAppModule(ConsumingApp.PATIENT, appModuleExtension);

export type ExtendedAppModule = typeof appModule;

export type ExtendedAppMutations = ExtendedAppModule['mutations'];
export type ExtendedAppActions = ExtendedAppModule['actions'];
export type ExtendedAppGetters = ExtendedAppModule['getters'];
