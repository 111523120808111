import { RouteConfig } from 'vue-router';

import { CustomRouteMeta } from '@/router/model';

/**
 * Inject custom route meta into all routes and their children.
 */
export default (routes: RouteConfig[], meta: CustomRouteMeta) => routes.map((route) => ({
  ...route,
  children: route.children?.map((childRoute) => ({
    ...childRoute,
    meta: {
      ...childRoute.meta,
      ...meta,
    },
  })),
  meta: {
    ...route.meta,
    ...meta,
  },
}));
