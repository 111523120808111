
import {
  BasicCard,
  ButtonComponent,
  Circles,
  Copy,
  getStore,
  useNavBarHeight,
} from '@caresend/ui-components';
import { computed, defineComponent, onMounted, ref } from 'vue';

import { formatFullName } from '@/functions/user';
import { userSettingsRoute } from '@/router/locations';

export default defineComponent({
  name: 'Home',
  components: {
    BasicCard,
    ButtonComponent,
    Circles,
    Copy,
  },
  setup() {
    const store = getStore();

    const headerBg = ref<HTMLElement | null>(null);

    onMounted(() => {
      const { syncContentMarginWithNavBarHeight } = useNavBarHeight();
      syncContentMarginWithNavBarHeight(headerBg);
    });

    const formattedName = computed<string>(() =>
      formatFullName(store.state.auth.user ?? null),
    );

    return {
      headerBg,
      formattedName,
      userSettingsRoute,
    };
  },
});
