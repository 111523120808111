import { FeatureFlag } from '@caresend/types';
import { Route, RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesBooking: RouteConfig[] = [
  {
    path: '/:partnerName?/orders',
    name: routeNames.ORDERS,
    component: () => import(
      /* webpackChunkName: 'chunk-booking' */
      '@/views/Orders.vue'
    ),
    meta: {
      allowIncomplete: true,
      featureFlag: FeatureFlag.PATIENT_INITIATED_BOOKING,
    },
  },
  {
    path: '/:partnerName?/booking/new/:productSlugName/',
    redirect: (to: Route) => {
      const name = routeNames.CREATE_BOOKING;
      return { ...to, name };
    },
  },
];

export { routesBooking };
