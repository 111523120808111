import { ByID, DbRef, Procedure, WithFriendlyStatus } from '@caresend/types';
import { firebaseBind, firebaseUnbind } from '@caresend/ui-components';
import update from 'immutability-helper';

import { ProceduresActions, ProceduresState } from '@/store/modules/procedures/model';

type S = ProceduresState;

const proceduresState: S = {
  procedures: {},
};

export const proceduresMutations = {
  'procedures/DELETE_PROCEDURE': (state: S, procedureID: string) => {
    state.procedures = update(state.procedures ?? {}, {
      $unset: [procedureID],
    });
  },

  'procedures/RESET_PROCEDURES': (state: S) => {
    state.procedures = {};
  },

  'procedures/SET_PROCEDURE': (state: S, procedure: WithFriendlyStatus<Procedure>) => {
    const procedureID = procedure.id;

    state.procedures = update(state.procedures ?? {}, {
      [procedureID]: { $set: procedure },
    });
  },

  'procedures/SET_PROCEDURES': (state: S, procedures: ByID<WithFriendlyStatus<Procedure>>) => {
    state.procedures = {
      ...state.procedures,
      ...procedures,
    };
  },
};

export const proceduresActions: ProceduresActions = {
  'procedures/bindProcedure': ({ commit }, procedureID) => new Promise<void>((resolve) => {
    const path = `${DbRef.PROCEDURES}/${procedureID}`;
    firebaseBind<Procedure>(path, (procedure) => {
      commit('procedures/SET_PROCEDURE', procedure);
      resolve();
    });
  }),

  'procedures/unbindProcedure': ({ commit }, procedureID) => {
    const path = `${DbRef.PROCEDURES}/${procedureID}`;
    firebaseUnbind(path);
    commit('procedures/DELETE_PROCEDURE', procedureID);
  },
};

export const proceduresGetters = {
};

export type ProceduresGetters = typeof proceduresGetters;

export const proceduresModule = {
  state: proceduresState,
  mutations: proceduresMutations,
  actions: proceduresActions,
  getters: proceduresGetters,
};

export type ProceduresModule = typeof proceduresModule;
