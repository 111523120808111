import {
  AuthStatus,
  EncryptedUser,
  GetIncompleteUserAccessMetadataRequest,
  LoginStep,
  Role,
} from '@caresend/types';
import {
  BasicCard,
  ButtonComponent,
  CardWithHeaderLight,
  Loading,
  TextInput,
  getRoute,
  getRouter,
  getStore,
  reportException,
  signIn,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import { errorSuffix, getUnknownErrorMessage, initPatient } from '@caresend/utils';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';

import EmailInput from '@/components/inputs/EmailInput.vue';
import IncompleteLogin from '@/components/login/IncompleteLogin.vue';
import { FROM_CARESEND_EMAIL } from '@/database/credentials';
import {
  getIncompleteUserAccessMetadataRequest,
  getTokenForIncompleteUserAccessRequest,
  getUserPasswordCreatedRequest,
  handleWarmUpError,
  resetPasswordRequest,
} from '@/database/firebase/API';
import { incompleteSignIn, registerUserWithoutPassword, resetPassword } from '@/database/firebase/auth';
import { sendEmail } from '@/functions/contact';

interface State {
  currentStep: LoginStep;
  email: string;
  isEmailValid: boolean;
  isLoading: boolean;
  isLoggingIn: boolean;
  password: string;
  previousStep: LoginStep;
}

export const loginLogic = defineComponent({
  name: 'LoginPage',
  components: {
    BasicCard,
    ButtonComponent,
    CardWithHeaderLight,
    EmailInput,
    Loading,
    TextInput,
    IncompleteLogin,
  },
  setup() {
    const route = getRoute();
    const router = getRouter();
    const store = getStore();

    const loginLoading = ref(false);
    /**
     * `undefined` signifies that the controller has not yet determined whether
     * the user is logging in with an incomplete account.
     */
    const isIncompleteLogin = ref<boolean | undefined>(undefined);
    const schedulingForUserID = ref<string | undefined>();
    const schedulingForUserName = ref<string | undefined>();
    const userFirstName = ref('');
    /** Will display at top of page if defined. */
    const errorMessage = ref<string | null>(null);

    // Query and param values
    const nextRoute = computed<string | undefined>(() =>
      route.value.query?.redirect as string | undefined,
    );
    const bookingID = computed<string | undefined>(() =>
      route.value.query?.bookingID as string | undefined,
    );
    const draftWaypointID = computed<string | undefined>(() =>
      route.value.query?.draftWaypointID as string | undefined,
    );
    const deferSignup = computed<boolean>(() =>
      route.value.query?.deferSignup === 'true',
    );
    const continueFlow = computed<boolean>(() =>
      route.value.query?.continueFlow === 'true',
    );
    const partnerName = computed<string>(() =>
      route.value.params?.partnerName ?? '',
    );

    // EncryptedUser authentication values
    const authStatus = computed<AuthStatus>(() => store.getters['auth/getAuthStatus']);
    const user = computed<EncryptedUser | undefined>(() => store.getters['auth/getUser']);

    const allowRoute = computed<boolean>(() =>
      !!user.value
      && user.value.role === Role.PATIENT
      && authStatus.value === AuthStatus.COMPLETE,
    );

    const state = reactive<State>({
      currentStep: LoginStep.ENTER_EMAIL,
      email: '',
      isEmailValid: false,
      isLoading: false,
      isLoggingIn: false,
      password: '',
      previousStep: LoginStep.ENTER_EMAIL,
    });

    onMounted(async () => {
      // warming up cloud functions to speed up loading time
      getTokenForIncompleteUserAccessRequest({ warmUp: true })
        .catch(handleWarmUpError);
      getUserPasswordCreatedRequest({ warmUp: true })
        .catch(handleWarmUpError);
      resetPasswordRequest({ warmUp: true })
        .catch(handleWarmUpError);

      if (!route.value.query.allowIncomplete) {
        isIncompleteLogin.value = false;
        return;
      }

      state.isLoading = true;

      const request: GetIncompleteUserAccessMetadataRequest = {
        role: Role.PATIENT,
      };

      request.bookingID = bookingID.value;
      request.draftWaypointID = draftWaypointID.value;
      const { isPatientInitiated } = store.state.schedulingFlow;

      // Skip request if no ID was included or if scheduling flow is patient initiated
      if (Object.keys(request).length === 1 || isPatientInitiated) {
        state.isLoading = false;
        isIncompleteLogin.value = false;
        return;
      }

      try {
        const {
          isIncomplete,
          firstName,
          schedulingForID,
          schedulingForName,
        } = await getIncompleteUserAccessMetadataRequest(request);

        isIncompleteLogin.value = isIncomplete;
        userFirstName.value = firstName || '';
        if (schedulingForID && schedulingForName) {
          schedulingForUserID.value = schedulingForID;
          schedulingForUserName.value = schedulingForName;
        }
      } catch (error) {
        reportException(error);
        const message = getUnknownErrorMessage(error);
        errorMessage.value = `Something went wrong: ${message} ${errorSuffix}`;
      }

      state.isLoading = false;
    });

    const incompleteLogin = async (dateOfBirth: number) => {
      const hasNeededID = bookingID.value || draftWaypointID.value;
      if (!hasNeededID || !dateOfBirth) {
        toastErrorAndReport(
          `Something went wrong. ${errorSuffix}`,
          `Missing data for incomplete login. bookingID: ${bookingID.value},
            draftWaypointID: ${draftWaypointID.value},
            dateOfBirth: ${dateOfBirth}`,
        );
        return;
      }

      loginLoading.value = true;
      await incompleteSignIn({
        bookingID: bookingID.value,
        draftWaypointID: draftWaypointID.value,
        dateOfBirth,
        proxyAuthID: schedulingForUserID.value,
      });
      loginLoading.value = false;
    };

    watch(allowRoute, () => {
      if (allowRoute.value) {
        const path = nextRoute.value ?? `/${partnerName.value}`;
        const query: Record<string, string> = {};
        if (continueFlow.value) query.continueFlow = String(continueFlow.value);
        router.push({ path, query });
      }
    });

    const containerClassNames = computed(() => {
      const names = [];
      // Hide login form during log in to prevent the login page flashing on
      // the screen after login completes.
      if (state.isLoggingIn) names.push('LoginPage__container--transparent');
      return names;
    });

    const passwordInput = ref<InstanceType<typeof TextInput> | null>(null);

    const setIsEmailValid = (value: boolean) => { state.isEmailValid = value; };

    const login = async () => {
      state.isLoading = true;
      state.isLoggingIn = true;
      try {
        await signIn(state.email, state.password);
      } catch {
        state.isLoggingIn = false;
      }
      state.isLoading = false;
    };

    const goToStep = (nextStep: LoginStep) => {
      state.previousStep = state.currentStep;
      state.currentStep = nextStep;
    };

    const goToPreviousStep = () => {
      goToStep(state.previousStep);
    };

    const sendResetPasswordEmail = async () => {
      state.isLoading = true;
      const searchParams = [];
      if (continueFlow.value) searchParams.push('continueFlow=true');
      let redirect = `${nextRoute.value}`;
      if (searchParams.length) redirect += `?${searchParams.join('&')}`;
      if (partnerName.value) redirect = `/${partnerName.value}${redirect}`;
      await resetPassword(state.email, redirect);
      state.isLoading = false;
    };

    const getUserPasswordCreated = async () => {
      if (!state.isEmailValid) return;
      state.isLoading = true;
      const { userID, passwordCreated, role }
        = await getUserPasswordCreatedRequest({ email: state.email });
      const userIsPatient = role === Role.PATIENT;
      if (!userID) {
        if (deferSignup.value) {
          const newUser = initPatient();
          newUser.info.email = state.email;
          try {
            await registerUserWithoutPassword(newUser, undefined, true);
          } catch (error) {
            toastError('Something went wrong creating your account. Please try again.');
            state.isLoading = false;
          }
          return;
        }
        goToStep(LoginStep.SIGNUP);
      } else if (!userIsPatient) {
        toastError('This user is not a patient');
      } else if (passwordCreated) {
        goToStep(LoginStep.ENTER_PASSWORD);
      } else {
        await sendResetPasswordEmail();
        goToStep(LoginStep.RESET_PASSWORD);
      }
      state.isLoading = false;
    };

    const getCurrentLoginStep = (step: LoginStep) => {
      switch (step) {
        case LoginStep.ENTER_EMAIL:
          return {
            textButton: 'Next',
            label: '',
            value: state.email,
            title: `Enter your email to ${continueFlow.value ? 'continue' : 'start'}`,
            input: (value: string) => { state.password = value; },
            clickButton: getUserPasswordCreated,
            buttonDisabled: !state.isEmailValid,
            clickBottomButton: () => sendEmail(FROM_CARESEND_EMAIL),
          };
        case LoginStep.ENTER_PASSWORD:
          return {
            textButton: 'Login',
            label: 'Your password',
            value: state.password,
            title: 'Log in to CareSend',
            input: (value: string) => { state.password = value; },
            bottomSentence: 'Forgot your password?',
            bottomButtonText: 'Set a new one',
            displayBackButton: true,
            goToPreviousStep: () => goToStep(LoginStep.ENTER_EMAIL),
            clickButton: login,
            clickBottomButton: async () => {
              await sendResetPasswordEmail(); goToStep(LoginStep.RESET_PASSWORD);
            },
          };
        case LoginStep.RESET_PASSWORD:
          return {
            textButton: 'Login',
            label: 'We’ve sent you a link to reset your password. Please check your email.',
            bottomSentence: 'Didn’t get the email?',
            bottomButtonText: 'Send again',
            title: 'Log in to CareSend',
            displayBackButton: true,
            goToPreviousStep,
            clickButton: () => { goToStep(LoginStep.ENTER_PASSWORD); },
            clickBottomButton: sendResetPasswordEmail,
          };
        case LoginStep.SIGNUP:
          return {
            goToPreviousStep: () => goToStep(LoginStep.ENTER_EMAIL),
          };
      }
    };

    const emailInputClassNames = computed(() => {
      const names = [];
      if (state.currentStep !== LoginStep.ENTER_EMAIL) {
        names.push('LoginPage__hidden-input');
      }
      return names;
    });

    const passwordInputClassNames = computed(() => {
      const names = [];
      if (state.currentStep !== LoginStep.ENTER_PASSWORD) {
        names.push('LoginPage__hidden-input');
      }
      return names;
    });

    const currentStepDetail = computed(() => getCurrentLoginStep(state.currentStep));

    watch(
      () => state.currentStep,
      () => {
        // Auto-focus password input
        if (passwordInput.value) {
          const inputEl = passwordInput.value.$el?.querySelector('input');
          setTimeout(() => {
            inputEl?.focus?.();
          }, 0);
        }
      },
    );

    return {
      ...toRefs(state),
      containerClassNames,
      currentStepDetail,
      emailInputClassNames,
      errorMessage,
      incompleteLogin,
      isIncompleteLogin,
      loginLoading,
      LoginStep,
      partnerName,
      passwordInput,
      passwordInputClassNames,
      schedulingForUserName,
      setIsEmailValid,
      userFirstName,
    };
  },
});
