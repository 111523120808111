/* eslint-disable @typescript-eslint/no-empty-function */
import { Booking, ByID, Order, OrderData } from '@caresend/types';
import { AnyGetters, reportException, setOrderDataInStore } from '@caresend/ui-components';
import { getUnknownErrorMessage, nullishFilter } from '@caresend/utils';

import { getUserOrdersRequest } from '@/database/firebase/API';
import { errorSuffix } from '@/store/helpers';
import type { CustomStore, RootState } from '@/store/model';
import { OrdersActions, OrdersState } from '@/store/modules/orders/model';

type S = OrdersState;

const ordersState: S = {
  orders: {},
  /** Ordered list of order IDs to display */
  orderList: [],
  loading: false,
  errorMessage: null,
};

export const ordersMutations = {
  'orders/RESET_ORDERS': (state: S) => {
    state.orders = {};
  },

  'orders/SET_FETCH_ORDERS_ERROR_MESSAGE': (state: S, errorMessage: string | null) => {
    state.errorMessage = errorMessage;
  },

  'orders/SET_ORDER_LIST': (state: S, orderData: OrderData[]) => {
    state.orderList = orderData.map((order) => order.id);
  },

  'orders/SET_ORDERS': (state: S, orders: ByID<Order>) => {
    state.orders = {
      ...state.orders,
      ...orders,
    };
  },

  'orders/SET_ORDERS_LOADING': (state: S, loading: boolean) => {
    state.loading = loading;
  },
};

export const ordersActions: OrdersActions = {
  // eslint-disable-next-line object-shorthand
  async 'orders/fetchOrders'(this: CustomStore, { commit }) {
    commit('orders/SET_ORDERS_LOADING', true);
    commit('orders/SET_FETCH_ORDERS_ERROR_MESSAGE', null);

    try {
      // TODO: implement proper pagination
      const { orders } = await getUserOrdersRequest({
        includeBookingsDataInOrderData: true,
        includeProceduresDataInBookingData: true,
        includeWaypointActionsDataInProcedureData: true,
        includeWaypointDataInWaypointActionData: true,
        includePrescriberDataInOrderData: true,
        includeItineraryDataInWaypointData: true,
      });

      setOrderDataInStore(commit, orders, this);
      commit('orders/SET_ORDER_LIST', orders);
    } catch (error) {
      const message = `There was a problem loading your bookings:
        ${getUnknownErrorMessage(error)} ${errorSuffix}`;
      commit('orders/SET_FETCH_ORDERS_ERROR_MESSAGE', message);
      reportException(error);
    } finally {
      commit('orders/SET_ORDERS_LOADING', false);
    }
  },
};

export const ordersGetters = {
  /** Returns the first booking for each order */
  'orders/getBookingsFromOrderList': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): Booking[] | null => {
    if (!state.orders || !state.orderList) return null;

    const firstBookingsInOrders: Booking[] = state.orderList
      .map<Booking | null>((orderID) => {
      const order = state.orders[orderID];
      if (!order) return null;
      const firstBookingID = Object.keys(order.bookings ?? {})[0];
      if (!firstBookingID) return null;
      return rootState.bookings.bookings[firstBookingID] ?? null;
    }).filter(nullishFilter);

    return firstBookingsInOrders;
  },

  'orders/getBookingsByDate': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (includePastBookings: boolean): Booking[] | null => {
    const firstBookingsInOrders = ordersGetters[
      'orders/getBookingsFromOrderList'
    ](state, getters, rootState);

    if (!firstBookingsInOrders) return null;

    // Currently showing bookings created in the last two weeks by default.

    const twoWeeksAgo = Date.now() - (1000 * 60 * 60 * 24 * 14);

    const olderBookings: Booking[] = firstBookingsInOrders.filter(
      (booking) => {
        const bookingDate = booking.creationTimestamp ?? 0;
        return bookingDate < twoWeeksAgo;
      },
    );

    const newerBookings: Booking[] = firstBookingsInOrders.filter(
      (booking) => {
        const bookingDate = booking.creationTimestamp ?? 0;
        return bookingDate > twoWeeksAgo;
      },
    );

    return [
      ...newerBookings.reverse(),
      ...(includePastBookings ? olderBookings.reverse() : []),
    ];
  },

  'orders/getOrderByBookingID': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (bookingID: string): Order | null => {
    const booking = rootState.bookings.bookings[bookingID];
    if (!booking?.orderID) return null;
    return state.orders[booking.orderID] ?? null;
  },
};

export type OrdersGetters = typeof ordersGetters;

export const ordersModule = {
  state: ordersState,
  mutations: ordersMutations,
  actions: ordersActions,
  getters: ordersGetters,
};

export type OrdersModule = typeof ordersModule;
