
import { TrackingEvent } from '@caresend/types';
import {
  Loading,
  LoadingState,
  NavBar,
  NavItem,
  NoTimeSlotsEventPayload,
  TransitionDownUp,
  getRoute,
  getStore,
  useNavBarHeight,
} from '@caresend/ui-components';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';

import { warmUpCloudFunctions } from '@/database/firebase/API';
import { showIntercomMessenger } from '@/database/intercom/methods';
import { trackEventSegment } from '@/functions/tracking';
import { finishSignUpRoute, loginRoute, userSettingsRoute } from '@/router/locations';
import { PasswordMode } from '@/views/signUp/CreatePassword/model';

export default defineComponent({
  name: 'App',
  components: {
    Loading,
    NavBar,
    TransitionDownUp,
  },
  setup() {
    const store = getStore();
    const route = getRoute();

    const contentElement = ref<HTMLElement | null>(null);

    onMounted(() => {
      const { syncContentMarginWithNavBarHeight } = useNavBarHeight();
      syncContentMarginWithNavBarHeight(contentElement);
      warmUpCloudFunctions();
    });

    const loadingState = computed<LoadingState | null>(() =>
      store.getters['app/getLoadingState'],
    );

    const loadingMessage = computed<string | null>(() =>
      store.getters['app/getLoadingMessage'],
    );

    const isLoadingBlocking = computed<boolean>(() =>
      store.getters['app/getIsLoadingBlocking'],
    );

    const isWhiteBackground = computed<boolean>(() => {
      const whiteBgMeta = route.value.meta?.whiteBackground;
      const isWhiteBg = typeof whiteBgMeta === 'function'
        ? whiteBgMeta({ store }).value
        : !!whiteBgMeta;

      return isWhiteBg;
    });

    const ignoreNarrowContent = computed<boolean>(() => {
      const ignoreNarrowMeta = route.value.meta?.ignoreNarrowContent;
      return ignoreNarrowMeta ?? false;
    });

    const appContentClass = computed<string[]>(() => {
      const classes = ['App__content'];
      if (!ignoreNarrowContent.value) {
        classes.push('App__content--narrow');
      }
      return classes;
    });

    // Set the custom background color on the <html> element to prevent it from
    // not filling the background on long screens due to fixed `height: 100%`
    // on this component.
    watch(
      () => isWhiteBackground.value,
      (isWhite) => {
        if (isWhite) {
          document.documentElement.classList.add('white-bg');
        } else {
          document.documentElement.classList.remove('white-bg');
        }
      },
      { immediate: true },
    );

    const passwordNotCreated = computed<boolean>(
      () => store.state.auth.user?.passwordCreated === false,
    );

    const partnerLogoUrl = computed<string | undefined>(
      () => store.state.offices.partnerInfo?.logos?.medium?.url,
    );
    const partnerName = computed<string | undefined>(() => store.state.offices.partnerInfo?.name);

    const createPasswordRoute = finishSignUpRoute({
      query: { passwordMode: PasswordMode.INCOMPLETE_CREATE },
    });

    const navItemsForLoggedInUser = computed<NavItem[]>(() => [
      ...(passwordNotCreated.value ? [{
        label: 'Create password',
        icon: 'lock',
        to: createPasswordRoute,
      }] : []),
      {
        label: 'Settings',
        icon: 'cog',
        to: userSettingsRoute(),
      },
    ]);

    const trackEmptySchedulingPage = (payload: NoTimeSlotsEventPayload) => {
      trackEventSegment(TrackingEvent.EMPTY_SCHEDULING_EVENT, {
        ...payload,
      });
    };

    return {
      appContentClass,
      contentElement,
      isLoadingBlocking,
      loadingMessage,
      loadingState,
      loginRoute,
      navItemsForLoggedInUser,
      partnerLogoUrl,
      partnerName,
      showIntercomMessenger,
      trackEmptySchedulingPage,
    };
  },
});
